import { ScaleFade } from "@chakra-ui/react";
import { useCancelBooking, useExternalBookingSettings, useWebBooking } from "api/apiHooks";
import { Button, Stack, ThemeSpaceVariable, useDisclosure } from "caspeco-casper-ui";
import CancelModal from "components/cancel-modal";
import ErrorBoundary from "components/error-boundary/ErrorBoundary";
import { useError } from "context/error-context";
import { useBookingIdParam, useConfirmWaitListBooking } from "hooks";
import { t } from "i18next";
import { trackEvent } from "logging/insights";
import { Articles } from "pages/booking-details/articles";
import BookingStatusComp from "pages/booking-details/booking-status/booking-status";
import { BookingSummary } from "pages/booking-details/booking-summary/booking-summary";
import CancelButton from "pages/booking-details/cancel-booking/cancel-button";
import ResturantInformation from "pages/booking-details/resturant-information";
import { ErrorView } from "pages/error-view";
import { useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
    cancelAllowed,
    hasAvailableWaitListOffers,
    hasBookingPassed,
    hasWaitListOffers,
    isBookingCanceled,
    isWaitList,
    isWaitListOfferLocked,
} from "utils/booking";
import BookMoreButton from "./book-more-button";
import BookingCancelNotPossibleAlert from "./booking-cancel-not-possible-alert";
import BookingDetailsSkeleton from "./booking-details-skeleton";
import BookingOverview from "./booking-overview";

export default function BookingDetails() {
    const { isLoading: isLoadingBooking, data: webBooking, isError } = useWebBooking();
    const { isLoading: isLoadingSettings } = useError();
    const settings = useExternalBookingSettings();
    const bookingId = useBookingIdParam();
    const {
        mutate,
        isSuccess: cancelSuccessful,
        isPending: isCancelLoading,
    } = useCancelBooking(bookingId);
    const { mutate: confirmMutate, isPending: confirmIsLoading } = useConfirmWaitListBooking(
        bookingId,
        webBooking
    );
    const { isOpen, onClose, onOpen } = useDisclosure();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const possibleToCancel = cancelAllowed(webBooking, settings);
    useEffect(() => {
        if (possibleToCancel) {
            const isCancel = searchParams.get("isCancel") === "true";
            const queryParams = new URLSearchParams(window.location.search);
            if (isCancel && possibleToCancel) {
                queryParams.delete("isCancel");
                const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
                navigate(newUrl, { replace: true });
                onOpen();
            }
        }
    }, [navigate, onOpen, searchParams, webBooking?.status, possibleToCancel]);

    const location = useLocation();
    const isFinal = location.state?.isFinal;

    const isCanceled = isBookingCanceled(webBooking);
    const isWaitListBooking = isWaitList(webBooking);
    const hasPassed = hasBookingPassed(webBooking);

    const isOfferLocked = isWaitListOfferLocked(webBooking);
    const hasOffers = hasWaitListOffers(webBooking);
    const hasAvailableOffers = hasAvailableWaitListOffers(webBooking);

    const onCancelBookingClick = () => {
        mutate();
        onClose();
        trackEvent("CancelBookingClicked", {
            guests: webBooking.guests,
            children: webBooking.guestsChildren,
            date: webBooking.start.toISOString(),
            isWaitingList: isWaitListBooking,
        });
    };

    if (isError) return <ErrorView />;
    if (isLoadingSettings || isLoadingBooking) return <BookingDetailsSkeleton />;

    const shouldShowCancelBtn = possibleToCancel && !isFinal && !cancelSuccessful && !isOfferLocked;
    const bookingCanceled = cancelSuccessful || isCanceled;
    const shouldShowBookingSummary = (!bookingCanceled && !hasPassed && !isOfferLocked) || isFinal;
    const disableOverview =
        !isFinal &&
        (bookingCanceled || hasPassed || isOfferLocked || (hasOffers && !hasAvailableOffers));
    const shouldShowConfrimBookingBtn = hasAvailableOffers && !disableOverview && !isOfferLocked;
    const shouldHideBoookingOverview = isOfferLocked && !hasPassed && !isFinal;
    return (
        <ErrorBoundary fallback={<ErrorView />}>
            <ScaleFade
                className="scale-fade"
                initialScale={0.9}
                in={true}
                transition={{ enter: { duration: 0.5 } }}
            >
                <Stack spacing={ThemeSpaceVariable.Large}>
                    <BookingStatusComp isFinal={isFinal} isCanceled={cancelSuccessful} />
                    {!shouldHideBoookingOverview && (
                        <BookingOverview disableOverview={disableOverview} />
                    )}
                    {shouldShowConfrimBookingBtn && (
                        <Button
                            variant="primary"
                            isLoading={confirmIsLoading}
                            onClick={() => confirmMutate()}
                        >
                            {t("waitList.confirmBooking")}
                        </Button>
                    )}
                    {shouldShowBookingSummary && <BookingSummary />}
                    {webBooking?.nonTableArticles.size > 0 && <Articles />}

                    {shouldShowCancelBtn ? (
                        <CancelButton onClick={onOpen} isLoading={isCancelLoading} />
                    ) : !isFinal && !cancelSuccessful && !isOfferLocked && !isCanceled ? (
                        <BookingCancelNotPossibleAlert />
                    ) : (
                        <BookMoreButton />
                    )}

                    <ResturantInformation />
                </Stack>
                <CancelModal
                    isOpen={isOpen}
                    handleCancelModalModalClose={onClose}
                    handleCancelModalCancel={onCancelBookingClick}
                />
            </ScaleFade>
        </ErrorBoundary>
    );
}
