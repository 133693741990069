import "./change-url";
import { asDate, asInt, asIntArray, asString } from "./parse";
export { setSearch } from "./parse";

export type UrlParams = {
    date: moment.Moment;
    guestsChildren: number;
    sectionIds: number[];
    system: string;
    webBookingId: string;
    guests: number;
    unitId: number;
    start: string;
    end: string;
    ruleId: number;
    ChargeId: string;
};

let lastUrl = "";
let cache: UrlParams;
// eslint-disable-next-line prefer-const
export let getUrlParams = () => {
    if (window.location.href !== lastUrl) {
        lastUrl = window.location.href;
        cache = {
            system: asString("system", null),
            unitId: asInt("unitId"),
            sectionIds: asIntArray("sectionIds"),
            // Query Strings
            webBookingId: asString("webBookingId", null),
            start: asString("start"),
            end: asString("end"),
            // Date moment
            date: asDate("date"),
            // Query Number
            ruleId: asInt("r"),
            guests: asInt("guests"),
            guestsChildren: asInt("children"),
            ChargeId: asString("ChargeId", null),
        };
    }
    return cache;
};
